<template>
  <nav
    class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs bg-white sitedashboard-navbar"
    id="sidenav-main"
  >
    <div class="scrollbar-inner">
      <!-- Brand -->
      <div class="sidenav-header align-items-center">
        <router-link class="navbar-brand pl-0" to="/">
          <img src="/melior_dk.svg" class="navbar-brand-img" alt="logo" />
        </router-link>
      </div>
      <div class="navbar-inner">
        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="sidenav-collapse-main">
          <ul class="navbar-nav mb-4">
            <li class="nav-item">
              <router-link class="nav-link incoming" to="/incoming-release">
                <MusicIcon size="17"></MusicIcon>
                <span class="nav-link-text pl-2">Incoming Release</span>
              </router-link>
            </li>
          </ul>

          <!-- Nav items -->
          <ul
            class="navbar-nav mb-4"
            v-for="(item, index) in menus"
            :key="index"
          >
            <p class="subtitle">{{ item.title }}</p>
            <li
              class="nav-item"
              v-for="(menu, index) in item.menu"
              :key="index"
              v-if="checkRoleAbility(menu)"
            >
              <router-link class="nav-link" :to="menu.route">
                <HomeIcon v-if="menu.icon == 'products'" size="17"></HomeIcon>
                <UsersIcon
                  v-else-if="menu.icon == 'artists'"
                  size="17"
                ></UsersIcon>
                <MusicIcon
                  v-else-if="menu.icon == 'labels'"
                  size="17"
                ></MusicIcon>
                <MusicIcon
                  v-else-if="menu.icon == 'music'"
                  size="17"
                ></MusicIcon>
                <PieChartIcon
                  v-else-if="menu.icon == 'analytics'"
                  size="17"
                ></PieChartIcon>
                <DollarSignIcon
                  v-else-if="
                    menu.icon == 'accounting' ||
                    menu.icon == 'withdrawals' ||
                    menu.icon == 'advance'
                  "
                  size="17"
                ></DollarSignIcon>
                <UserCheckIcon
                  v-else-if="menu.icon == 'subscriptions'"
                  size="17"
                ></UserCheckIcon>
                <UsersIcon
                  v-else-if="menu.icon == 'users'"
                  size="17"
                ></UsersIcon>
                <FolderIcon
                  v-else-if="menu.icon == 'organization'"
                  size="17"
                ></FolderIcon>
                <SettingsIcon
                  v-else-if="menu.icon == 'settings'"
                  size="17"
                ></SettingsIcon>
                <BookIcon v-else-if="menu.icon == 'book'" size="17"></BookIcon>
                <span class="nav-link-text pl-2">{{ menu.name }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import {
  HomeIcon,
  CalendarIcon,
  PieChartIcon,
  UsersIcon,
  FileTextIcon,
  SettingsIcon,
  DollarSignIcon,
  FolderIcon,
  UserCheckIcon,
  MusicIcon,
  BookIcon,
} from "vue-feather-icons";
export default {
  components: {
    HomeIcon,
    MusicIcon,
    DollarSignIcon,
    FolderIcon,
    PieChartIcon,
    CalendarIcon,
    UsersIcon,
    FileTextIcon,
    UserCheckIcon,
    SettingsIcon,
    BookIcon,
  },
  data() {
    return {
      menus: [
        {
          title: "Catalog",
          menu: [
            {
              name: "Products",
              icon: "products",
              route: "/app",
            },
            {
              name: "Artists",
              icon: "artists",
              route: "/artists",
            },
          ],
        },
        {
          title: "Data",
          menu: [
            {
              name: "Analytics",
              icon: "analytics",
              route: "/analytics",
            },
            {
              name: "Earnings",
              icon: "accounting",
              route: "/earnings",
            },
            {
              name: "Subscriptions",
              icon: "subscriptions",
              route: "/subscriptions",
            },
            {
              name: "Withdrawals",
              icon: "withdrawals",
              route: "/withdrawals",
              role: "super-admin",
            },
            {
              name: "Accounting",
              icon: "accounting",
              route: "/accounting",
              role: "super-admin",
            },
            {
              name: "Blog",
              icon: "book",
              route: "/blog",
              role: "super-admin",
            },
            {
              name: "Lynks Signups",
              icon: "users",
              route: "/lynks-signups",
              role: "super-admin",
            },
          ],
        },
        {
          title: "Settings",
          menu: [
            {
              name: "Release Updates",
              icon: "music",
              route: "/release-updates",
            },
            {
              name: "Advance Requests",
              icon: "advance",
              route: "/advance-requests",
            },
            {
              name: "Users",
              icon: "users",
              route: "/users",
              role: "super-admin",
            },
            {
              name: "Settings",
              icon: "settings",
              route: "/settings",
            },
          ],
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user || {};
    },
  },
  methods: {
    checkRoleAbility(menu) {
      if (menu.role) {
        return this.user.user_type === menu.role;
      }
      return true;
    },
  },
};
</script>
