import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);

export const getters = {
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
  notificationSystem: (state) => state.notificationSystem,
  user: (state) => {
    let dUser = Vue.CryptoJS.AES.decrypt(state.user, state.passPhrase).toString(
      Vue.CryptoJS.enc.Utf8
    );

    return JSON.parse(dUser);
  },
  passPhrase: (state) => state.passPhrase,
  isLoggedIn: (state) => !!state.token,
  token: (state) => {
    let dToken = Vue.CryptoJS.AES.decrypt(
      state.token,
      state.passPhrase
    ).toString(Vue.CryptoJS.enc.Utf8);

    return dToken;
  },
  audioPlay: (state) => state.audioPlay,
};
