import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

// import AppHeader from "../layout/AppHeader.vue";
// import AppFooter from "../layout/AppFooter.vue";
import Sidebar from "./layout/Sidebar.vue";
import TopBar from "./layout/TopBar.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/app",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    components: {
      default: () => import("./views/Login.vue"),
    },
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/app",
    name: "App",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/Home.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/incoming-release",
    name: "IncomingRelease",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/releases/Index.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/incoming-release/:id",
    name: "IncomingReleaseView",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/releases/IncomingReleaseView.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/release/:id",
    name: "ReleaseView",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/release/Index.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/artists",
    name: "Artists",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/artists/Index.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/artists/:id",
    name: "ArtistsPreview",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/artists/ArtistProfile.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/labels",
    name: "Labels",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/labels/Index.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/analytics",
    name: "Analytics",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/analytics/Index.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/earnings",
    name: "Earnings",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/earnings/Index.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/withdrawals",
    name: "Withdrawals",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/withdrawals/Index.vue"),
    },
    meta: {
      requiresAuth: true,
      roleRequired: "superadmin",
    },
  },
  {
    path: "/accounting",
    name: "Accounting",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/accounting/Index.vue"),
    },
    meta: {
      requiresAuth: true,
      roleRequired: "superadmin",
    },
  },
  {
    path: "/blog",
    name: "Blog",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/blog/Index.vue"),
    },
    meta: {
      requiresAuth: true,
      roleRequired: "superadmin",
    },
  },
  {
    path: "/add-blog",
    name: "AddBlog",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/blog/AddBlog.vue"),
    },
    meta: {
      requiresAuth: true,
      roleRequired: "superadmin",
    },
  },
  {
    path: "/blog/:id",
    name: "ViewBlog",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/blog/BlogView.vue"),
    },
    meta: {
      requiresAuth: true,
      roleRequired: "superadmin",
    },
  },
  {
    path: "/blog/edit/:id",
    name: "EditBlog",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/blog/BlogEdit.vue"),
    },
    meta: {
      requiresAuth: true,
      roleRequired: "superadmin",
    },
  },
  {
    path: "/lynks-signups",
    name: "LynksSignups",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/lynks-signups/Index.vue"),
    },
    meta: {
      requiresAuth: true,
      roleRequired: "superadmin",
    },
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/subscriptions/Index.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/organization",
    name: "Organization",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/organization/Index.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/users/Index.vue"),
    },
    meta: {
      requiresAuth: true,
      roleRequired: "superadmin",
    },
  },
  {
    path: "/user/update-password/:id",
    name: "UpdatePassword",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/users/UpdatePassword.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/create-admin",
    name: "CreateAdmin",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/users/CreateAdmin.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/settings/Index.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/release-updates",
    name: "ReleaseUpdates",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/release-updates/Index.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/advance-requests",
    name: "AdvanceRequests",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/advance-requests/Index.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/advance-requests/:id",
    name: "AdvanceRequestsView",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/advance-requests/ViewRequest.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next("/login");
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (to.meta.roleRequired === "superadmin") {
      const { user } = store.getters;

      if (user && user.user_type === "super-admin") {
        return next();
      } else {
        router.push("/app");
      }
    } else {
      return next();
    }

    if (store.getters.isLoggedIn) {
      next({
        path: "/app",
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
