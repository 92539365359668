<template>
  <div id="app">
    <router-view name="sidebar"></router-view>
    <div class="main-content" id="panel">
      <router-view name="topNav"></router-view>
      <div class="container-fluid my-4">
        <router-view />
      </div>
      <meliorPlayer />
    </div>
  </div>
</template>
<script>
import meliorPlayer from "./components/MeliorPlayer";
export default {
  name: "App",
  components: { meliorPlayer },
  data() {
    return {};
  },
  mounted(){
    console.log('Version 2.0.0')
  }
};
</script>
<style lang="scss">
@import "./assets/scss/main.scss";
.main-content {
  min-height: 100vh;
  position: relative;
}
</style>
