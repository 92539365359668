<template>
  <div class="">
    <nav
      aria-label="navbarTop"
      class="navbar navbar-top navbar-expand bg-white border-bottom-bb d-none d-lg-block py-0"
    >
      <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <p class="text-dark font-weight-bold mb-0 mr-sm-3">
            Welcome, {{ user.name }} 👋
          </p>
          <!-- Navbar links -->
          <ul class="navbar-nav align-items-center ml-md-auto">
            <li class="nav-item d-xl-none">
              <!-- Sidenav toggler -->

              <div
                class="pr-3 sidenav-toggler sidenav-toggler-dark"
                data-action="sidenav-pin"
                data-target="#sidenav-main"
              >
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                </div>
              </div>
            </li>
          </ul>
          <ul class="navbar-nav align-items-center ml-auto ml-md-0">
            <li class="nav-item d-flex align-items-center">
              <vs-dropdown v-if="user" class="outline-none">
                <a class="nav-link pr-0 pl-2" href="#">
                  <div class="media align-items-center">
                    <span
                      class="avatar avatar-sm rounded"
                      style="background: transparent"
                    >
                      <img
                        alt="user"
                        style="border-radius: 18%"
                        :src="user.profile_photo_url || ''"
                      />
                    </span>
                    <div class="userLogDetails">
                      <p class="name">{{ user.name }}</p>
                      <p class="email">{{ user.email }}</p>
                    </div>
                  </div>
                </a>
                <vs-dropdown-menu>
                  <vs-dropdown-item
                    @click="$router.push('/settings')"
                    class="px-4 py-2 text-right"
                  >
                    Settings
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="logout"
                    class="px-4 py-2 text-right"
                  >
                    Logout
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
              <button
                @click="sidebarActive = !sidebarActive"
                class="btn btn-link text-dark p-2"
              >
                <vs-icon icon="menu"></vs-icon>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <nav
      aria-label="navbarTop"
      class="navbar navbar-top bg-white border-bottom-bb d-block d-lg-none py-2"
    >
      <div class="">
        <div class="row align-items-center w-full mr-0">
          <div class="col-6">
            <p class="text-dark font-weight-bold mb-0 mr-sm-3 small pl-4">
              Welcome, {{ user.first_name }} 👋
            </p>
          </div>
          <div class="col-6 pr-0">
            <div class="text-right d-flex justify-content-end">
              <vs-dropdown v-if="user" class="outline-none">
                <a class="nav-link pr-0 pl-2" href="#">
                  <div class="media align-items-center">
                    <span
                      class="avatar avatar-sm rounded"
                      style="background: transparent"
                    >
                      <img
                        style="border-radius: 18%"
                        :src="user.profile_photo_url || ''"
                        alt="user"
                      />
                    </span>
                    <div class="userLogDetails">
                      <p class="name">{{ user.name }}</p>
                      <p class="email">{{ user.email }}</p>
                    </div>
                  </div>
                </a>
                <vs-dropdown-menu>
                  <vs-dropdown-item
                    @click="$router.push('/profile')"
                    class="px-4 py-2 text-right"
                  >
                    Profile
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="logout"
                    class="px-4 py-2 text-right"
                  >
                    Logout
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>

              <button
                @click="sidebarActive = !sidebarActive"
                class="btn btn-link text-dark p-2"
              >
                <vs-icon icon="menu"></vs-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <vs-sidebar
      parent="body"
      default-index="1"
      color="primary"
      class="sidebarx"
      spacer
      v-model="sidebarActive"
    >
      <div>
        <nav
          aria-label="navbar"
          class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs bg-white sitedashboard-navbar mb-sidebar"
          id="sidenav-main"
        >
          <div class="scrollbar-inner">
            <!-- Brand -->
            <div class="sidenav-header align-items-center">
              <router-link class="navbar-brand pl-0" to="/">
                <img src="/melior_dk.svg" class="navbar-brand-img" alt="logo" />
              </router-link>
            </div>
            <div class="navbar-inner px-0">
              <!-- Collapse -->
              <div class="px-4" id="sidenav-collapse-main">
                <ul class="navbar-nav mb-4">
                  <li class="nav-item">
                    <router-link
                      class="nav-link incoming"
                      to="/incoming-release"
                    >
                      <MusicIcon size="17"></MusicIcon>
                      <span class="nav-link-text pl-2">Incoming Release</span>
                    </router-link>
                  </li>
                </ul>

                <!-- Nav items -->
                <ul
                  class="navbar-nav mb-4"
                  v-for="(item, index) in menus"
                  :key="index"
                >
                  <p class="subtitle">{{ item.title }}</p>
                  <li
                    class="nav-item"
                    v-for="(menu, index) in item.menu"
                    :key="index"
                    v-if="checkRoleAbility(menu)"
                  >
                    <router-link class="nav-link" :to="menu.route">
                      <HomeIcon
                        v-if="menu.icon == 'products'"
                        size="17"
                      ></HomeIcon>
                      <UsersIcon
                        v-else-if="menu.icon == 'artists'"
                        size="17"
                      ></UsersIcon>
                      <MusicIcon
                        v-else-if="menu.icon == 'labels'"
                        size="17"
                      ></MusicIcon>
                      <PieChartIcon
                        v-else-if="menu.icon == 'analytics'"
                        size="17"
                      ></PieChartIcon>
                      <DollarSignIcon
                        v-else-if="
                          menu.icon == 'accounting' ||
                          menu.icon == 'withdrawals'
                        "
                        size="17"
                      ></DollarSignIcon>
                      <UserCheckIcon
                        v-else-if="menu.icon == 'subscriptions'"
                        size="17"
                      ></UserCheckIcon>
                      <UsersIcon
                        v-else-if="menu.icon == 'users'"
                        size="17"
                      ></UsersIcon>
                      <FolderIcon
                        v-else-if="menu.icon == 'organization'"
                        size="17"
                      ></FolderIcon>
                      <SettingsIcon
                        v-else-if="menu.icon == 'settings'"
                        size="17"
                      ></SettingsIcon>
                      <span class="nav-link-text pl-2">{{ menu.name }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>

        <!-- <vs-sidebar-item class="text-center" :active-class="'active'">
          <div class="d-flex align-items-center">
            <div class="mr-3">
              <img
                class="avatar avatar-sm rounded-circle"
                :src="getDisplay()"
                alt="..."
              />
            </div>
            <div class="text-left">
              <h6 class="mb-0">{{ user.first_name }}</h6>
              <small class="text-muted">{{ user.email }}</small>
            </div>
          </div>
        </vs-sidebar-item> -->
        <div></div>
      </div>
    </vs-sidebar>
  </div>
</template>
<script>
import Sidebar from "./Sidebar.vue";
import {
  HomeIcon,
  CalendarIcon,
  PieChartIcon,
  UsersIcon,
  FileTextIcon,
  SettingsIcon,
  DollarSignIcon,
  FolderIcon,
  MusicIcon,
  UserCheckIcon,
} from "vue-feather-icons";
export default {
  name: "TopBar",
  components: {
    HomeIcon,
    MusicIcon,
    DollarSignIcon,
    FolderIcon,
    PieChartIcon,
    CalendarIcon,
    UsersIcon,
    FileTextIcon,
    SettingsIcon,
    UserCheckIcon,
    Sidebar,
  },

  data() {
    return {
      sidebarActive: false,
      menus: [
        {
          title: "Catalog",
          menu: [
            {
              name: "Products",
              icon: "products",
              route: "/app",
            },
            {
              name: "Artists",
              icon: "artists",
              route: "/artists",
            },
          ],
        },
        {
          title: "Data",
          menu: [
            {
              name: "Analytics",
              icon: "analytics",
              route: "/analytics",
            },
            {
              name: "Earnings",
              icon: "accounting",
              route: "/earnings",
            },
            {
              name: "Subscriptions",
              icon: "subscriptions",
              route: "/subscriptions",
            },
            {
              name: "Withdrawals",
              icon: "withdrawals",
              route: "/withdrawals",
              role: "super-admin",
            },
            {
              name: "Accounting",
              icon: "accounting",
              route: "/accounting",
              role: "super-admin",
            },
          ],
        },
        {
          title: "Settings",
          menu: [
            {
              name: "Release Updates",
              icon: "edit",
              route: "/release-updates",
            },
            {
              name: "Users",
              icon: "users",
              route: "/users",
              role: "super-admin",
            },
            {
              name: "Settings",
              icon: "settings",
              route: "/settings",
            },
          ],
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user || {};
    },
  },
  methods: {
    checkRoleAbility(menu) {
      if (menu.role) {
        return this.user.user_type === menu.role;
      }
      return true;
    },
    getDisplay() {
      return "https://i.pravatar.cc/300";
      // if (this.user.avatar_url) {
      //   return this.user.avatar_url;
      // } else {
      //   return `https://ui-avatars.com/api/?name=${this.user.firstname}+${this.user.lastname}`;
      // }
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        location.href = "/";
      });
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.userLogDetails {
  padding: 0px 10px;
  text-align: left;
  color: black;
  .name {
    margin-bottom: 0px;
    font-size: 13px;
  }
  .email {
    margin-bottom: 0px;
    font-size: 10px;
  }
}
@media (max-width: 1199.98px) {
  .sidenav.mb-sidebar {
    transform: translateX(0px) !important;
  }
}
</style>
