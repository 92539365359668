<template>
  <!-- TODO: add Animation, displayCheck: audioPlay.status -->
  <div class="meliorPlayer" v-if="audioPlay.status">
    <vs-button
      @click="closeMedia"
      color="white"
      class="media-close"
      icon="close"
    ></vs-button>
    <vue-plyr>
      <audio controls crossorigin playsinline autoplay="true">
        <source :src="audioPlay.source" type="audio/mp3" />
      </audio>
    </vue-plyr>
  </div>
</template>
<script>
export default {
  computed: {
    audioPlay() {
      return this.$store.getters.audioPlay;
    },
  },
  methods: {
    closeMedia() {
      this.$store.commit("audioPlay", {
        status: false,
        source: "",
      });
    },
  },
};
</script>
<style lang="scss">
.meliorPlayer {
  position: fixed;
  bottom: 0;
  width: 84vw;
  z-index: 999;
  //   transition: 0.5s all;
  //   opacity: 0;
  //   &.displayCheck {
  //     opacity: 1;
  //   }
  .media-close {
    position: absolute;
    outline: 0;
    color: black;
    z-index: 9;
    border: 0px;
    left: 19px;
    top: -21px;
    background: white !important;
    border-radius: 35px !important;
    box-shadow: 0px 1px 12px rgb(0 0 0 / 8%);
    .vs-icon {
      font-size: 15px;
    }
  }
  .plyr--audio .plyr__controls {
    background: #f88f7f;
    padding: 30px;
  }
  .plyr--audio .plyr__control:hover {
    background: rgba(0, 0, 0, 0.171);
  }
  .plyr__controls .plyr__controls__item:first-child {
    color: black;
  }
  .plyr--audio .plyr__progress__buffer {
    background: white;
  }
  .plyr--full-ui input[type="range"] {
    color: black;
  }
}
// media query for mobile
@media only screen and (max-width: 768px) {
  .meliorPlayer {
    width: 100vw;
  }
}
</style>
