import axios from "@/axios.js";

export const actions = {
  authRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: payload.path,
        data: payload.data,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          localStorage.removeItem("token");
          reject(err);
        });
    });
  },
  delRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      const token = getters.token;

      axios({
        url: `${payload.path}`,
        method: "DELETE",
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  putRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      const token = getters.token;
      axios({
        url: payload.path,
        data: payload.data,
        method: "PUT",
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          if (err.response) {
            if (
              err.response.status === 401 ||
              err.response.status === 406 ||
              err.response.status === 403
              // || err.response.status === 500
            ) {
              dispatch("logout");
            }
          }
          reject(err);
        });
    });
  },

  deleteRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      const token = getters.token;
      axios({
        url: payload.path,
        method: "DELETE",
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          if (err.response) {
            if (
              err.response.status === 401 ||
              err.response.status === 406 ||
              err.response.status === 403
              // || err.response.status === 500
            ) {
              dispatch("logout");
            }
          }
          reject(err);
        });
    });
  },
  postRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      const token = getters.token;
      axios({
        url: payload.path,
        data: payload.data,
        method: "POST",
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          if (err.response) {
            if (
              err.response.status === 401 ||
              err.response.status === 406 ||
              err.response.status === 403
              // || err.response.status === 500
            ) {
              dispatch("logout");
            }
          }
          reject(err);
        });
    });
  },
  patchRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      const token = getters.token;
      axios({
        url: payload.path,
        data: payload.data,
        method: "PATCH",
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          if (err.response) {
            if (
              err.response.status === 401 ||
              err.response.status === 406 ||
              err.response.status === 403
              // || err.response.status === 500
            ) {
              dispatch("logout");
            }
          }
          reject(err);
        });
    });
  },
  getRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      const token = getters.token;

      axios({
        url: `${payload.path}`,
        method: "GET",
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          if (err.response) {
            if (
              err.response.status === 401 ||
              err.response.status === 406 ||
              err.response.status === 403
              // || err.response.status === 500
            ) {
              dispatch("logout");
            }
          }
          reject(err);
        });
    });
  },
  logout({ commit }) {
    commit("logout");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    delete axios.defaults.headers.common["Authorization"];
    return Promise.resolve();
  },
};
